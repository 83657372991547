import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function FruitsOfMyLabor() {
  return (
    <Layout>
      <Helmet title="Fruits of My Labor | Lucinda Williams" />

      <h1>Fruits of My Labor</h1>

      <h2>Lucinda Williams</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 62</h4>

      <Link to="/">List</Link>

      <Columns columns="3">
        <Verse>
          <p>
            Baby, <Ch>G</Ch>see how I been living
          </p>
          <p>
            Velvet <Ch>Am</Ch>curtains on the <Ch>C</Ch>windows to
          </p>
          <p>
            Keep the <Ch>G</Ch>bright and unforgiving
          </p>
          <p>
            Light from <Ch>Am</Ch>shining <Ch>C</Ch>through
          </p>
          <p>
            <Ch>G</Ch>Baby, I remember all the <Ch>C</Ch>things we did
          </p>
          <p>
            When <Ch>G</Ch>we slept together
          </p>
          <p>
            In the <Ch>C</Ch>blue behind your eyelids
          </p>
          <p>
            <Ch>Am</Ch>Baby, sweet <Ch>C</Ch>baby
          </p>
        </Verse>
        <Verse>
          <p>
            Traced your <Ch>G</Ch>scent through the gloom
          </p>
          <p>
            'Til I <Ch>Am</Ch>found these purple <Ch>C</Ch>flowers
          </p>
          <p>
            I was <Ch>G</Ch>spent, I was soon
          </p>
          <p>
            <Ch>Am</Ch>Smelling you for <Ch>C</Ch>hours
          </p>
          <p>
            <Ch>G</Ch>Lavender, lotus <Ch>C</Ch>blossoms too
          </p>
          <p>
            <Ch>G</Ch>Water the dirt, flowers <Ch>C</Ch>last for you
          </p>
          <p>
            <Ch>Am</Ch>Baby, Sweet <Ch>C</Ch>baby
          </p>
        </Verse>
        <Verse>
          <p>
            Tange<Ch>G</Ch>rines and persimmons
          </p>
          <p>
            And <Ch>Am</Ch>sugar<Ch>C</Ch>cane
          </p>
          <p>
            Grapes and <Ch>G</Ch>honeydew melon
          </p>
          <p>
            Enough <Ch>Am</Ch>fit for a <Ch>C</Ch>queen
          </p>
          <p>
            Lemon <Ch>G</Ch>trees don't make a <Ch>C</Ch>sound
          </p>
          <p>
            'Til <Ch>G</Ch>branches bend and fruit <Ch>C</Ch>falls to the ground
          </p>
          <p>
            <Ch>Am</Ch>Baby, Sweet <Ch>C</Ch>baby
          </p>
        </Verse>
        <Bridge>
          <p>
            <b>SOLO</b>
          </p>
        </Bridge>
        <Verse>
          <p>
            Come to <Ch>G</Ch>my world and witness
          </p>
          <p>
            The <Ch>Am</Ch>way things have <Ch>C</Ch>changed
          </p>
          <p>
            'Cause I <Ch>G</Ch>finally did it, baby
          </p>
          <p>
            I got <Ch>Am</Ch>out of La <Ch>C</Ch>Grange
          </p>
          <p>
            <Ch>G</Ch>Got my Mercury and <Ch>C</Ch>drove out west
          </p>
          <p>
            <Ch>G</Ch>Pedal to the metal and my <Ch>C</Ch>luck to the test
          </p>
          <p>
            <Ch>Am</Ch>Baby, Sweet <Ch>C</Ch>baby
          </p>
        </Verse>
        <Chorus>
          <p>
            I been <Ch>G</Ch>tryin' to enjoy
          </p>
          <p>
            All the <Ch>Am</Ch>fruits of my <Ch>C</Ch>labor
          </p>
          <p>
            I been <Ch>G</Ch>cryin' for you, boy
          </p>
          <p>
            But <Ch>Am</Ch>truth is my sav<Ch>C</Ch>ior
          </p>
          <p>
            <Ch>G</Ch>Baby, sweet baby
          </p>
          <p>
            If <Ch>C</Ch>it's all the same
          </p>
          <p>
            <Ch>G</Ch>Take the glory any <Ch>C</Ch>day over the fame
          </p>
          <p>
            <Ch>Am</Ch>Baby, sweet <Ch>C</Ch>baby
          </p>
        </Chorus>
        <Chorus>
          <p>
            I been <Ch>G</Ch>tryin' to enjoy
          </p>
          <p>
            All the <Ch>Am</Ch>fruits of my <Ch>C</Ch>labor
          </p>
          <p>
            I been <Ch>G</Ch>cryin' for you, boy
          </p>
          <p>
            But <Ch>Am</Ch>truth is my sav<Ch>C</Ch>ior
          </p>
          <p>
            <Ch>G</Ch>Baby, sweet baby
          </p>
          <p>
            If <Ch>C</Ch>it's all the same
          </p>
          <p>
            <Ch>G</Ch>Take the glory any <Ch>C</Ch>day over the fame
          </p>
          <p>
            <Ch>Am</Ch>Baby, sweet <Ch>C</Ch>baby
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}




